import TileWMS from "ol/source/TileWMS";
import OlView from "ol/View";
import Projection from "ol/proj/Projection";
import { Tile as TileLayer } from "ol/layer";
import { ol_layer_5 } from "../../../data/OlTileLayerConfig";
import { ol_view_5 } from "../../../data/OlViewConfig";

const { TileLayers } = ol_layer_5;

const layers = TileLayers.map(({ source, ...rest }) => {
  return new TileLayer({ source: new TileWMS(source), ...rest });
});

function id5Layers(vectors) {
  return [...layers, vectors];
}

const { code, units } = ol_view_5["projection"];

const projection = new Projection({
  code,
  units,
});

const id5View = new OlView({ ...ol_view_5, projection });

export { id5Layers, id5View };
