import React from "react";
import MainRoutes from "./MainRoutes";

export default function App(props) {
  function showSite() {
    return <MainRoutes />;
  }

  return showSite();
}
