import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

export default function Login() {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";

  const skedesplanRef = useRef();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  function goToSkedesplan(user, skedesplan_id, route) {
    sessionStorage.setItem("loggedIn", true);
    sessionStorage.setItem("role", user.role);

    if (route) {
      navigate(route, { state: { user, skedesplan_id } });
    } else {
      navigate("/skedesplan", { state: { user, skedesplan_id } });
    }
  }

  function goToSkedesplaner(User, route) {
    sessionStorage.setItem("loggedIn", true);
    //Create these variables to send state in a correct way to change-password & skedesplaner
    if (route) {
      const user = User[0];
      const userList = User;
      sessionStorage.setItem("role", User[0].role);
      navigate(route, { state: { user, userList } });
    } else {
      const user = User;
      navigate("/skedesplaner", { state: { user } });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
  let skedesplaner = [];

  async function handleSubmit(event) {
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: formData.username,
        password: formData.password,
      }),
    };
    const response = await fetch(`${apiURL}/login`, requestOptions);
    const data = await response.json();
    if (data.status) {
      let user = data.user;
      if (data.user.length === 1) {
        goToSkedesplan(user[0], user[0].skedesplan_id, data.route);
      } else {
        goToSkedesplaner(user, data.route);
      }
    } else {
      alert("Wrong password or username");
    }
  }

  skedesplanRef.current = skedesplaner.map((s) => s);
  function renderPage() {
    return (
      <>
        <Header />
        <div className="window-container">
          <form className="form" onSubmit={handleSubmit}>
            <input
              autoFocus
              type="email"
              placeholder="Email address"
              className="form-control"
              name="username"
              onChange={handleChange}
              value={formData.email}
            />
            <input
              type="password"
              placeholder="Password"
              className="form-control"
              name="password"
              onChange={handleChange}
              value={formData.password}
            />
            <button className="login-submit btn btn-primary">Login</button>
          </form>
        </div>
      </>
    );
  }
  return renderPage();
}
