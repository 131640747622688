import React from "react";
import { Outlet, Navigate } from "react-router-dom";

export default function ProtectedRoutes() {
  function useAuth() {
    let bool = sessionStorage.getItem("loggedIn");
    return bool;
  }
  let auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/login" />;
}
