import React, { useState } from "react";

export default function LegendItem(props) {
  const [checkbox, setCheckbox] = useState(true);
  const [toggle, setToggle] = useState(true);

  const checkboxesChecked = (e) => {
    if (e.target.id === props.name) {
      setCheckbox(!checkbox);
      const legendItems = document.getElementsByClassName(props.name);
      for (var legend of legendItems) {
        props.handleChange(legend.id, !checkbox);
        legend.checked = !checkbox;
      }
    }
  };

  const toggleMenue = (name) => {
    if (name === props.name) {
      setToggle(!toggle);
      const legendItems = document.getElementsByClassName(props.name);
      if (toggle) {
        for (let legendItem of legendItems) {
          legendItem.closest(".legend-item").style.display = "none";
        }
      } else {
        for (let legendItem of legendItems) {
          legendItem.closest(".legend-item").style.display = "flex";
        }
      }
    }
  };

  return (
    <div className="legend-item-heading-container">
      <input
        type="checkbox"
        id={props.name}
        name={props.name}
        disabled={!toggle}
        className="box bg-black"
        onChange={(e) => checkboxesChecked(e)}
        // checked={!toggle ? false : checkbox}
        defaultChecked={true}
      />

      <label htmlFor={props.name} className="legend-item-header">
        {props.name}
      </label>

      <button
        className="toggleLegendItems"
        name={props.name}
        onClick={(e) => toggleMenue(e.target.name)}
      >
        {toggle ? (
          <i className="fa-light fa-eye-slash"></i>
        ) : (
          <i className="fa-light fa-eye"></i>
        )}
      </button>
      {toggle ? props.children : ""}
    </div>
  );
}
