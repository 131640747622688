// To find the your skedesplan layers -- Use Ctrl+F (VSCode) and search for your skedesplan.
// All new layers should have a comment above with the name of the Skedesplan

// For demo layer 1, 2 & 3
const demo_layer = {
  TileLayers: [
    {
      opacity: 0.5,
      source: {
        url: "http://kartor.stockholm.se/bios/wms/app/baggis/web/WMS_STHLM_STOCKHOLMSKARTA_GRA_FORENKLAD?",
        params: {
          VERSION: "1.1.1",
        },
      },
    },
  ],
};

// Sundbyberg/SNS
export const ol_layer_1 = {
  TileLayers: [
    {
      opacity: 0.5,
      source: {
        //url: "https://kartor.stockholm.se/bios/wms/app/baggis/web/WMS_STHLM_STOCKHOLMSKARTA_GRA_FORENKLAD_NT_RASTER",
        url: "http://kartor.stockholm.se/bios/wms/app/baggis/web/WMS_STHLM_STOCKHOLMSKARTA_GRA_FORENKLAD_NT",
        params: {
          VERSION: "1.1.1",
        },
      },
    },
    /*{
      source: {
        url: "https://skedesplan.se/geoserver/sns/wms?",
        params: {
          VERSION: "1.1.1",
          LAYERS: "sns:sns_bakgrundskarta",
          FORMAT: "image/png",
        },
        crossOrigin: "anonymous",
      },
    },*/
  ],
};

// Demo
export const ol_layer_2 = demo_layer;

// Årstafältet
export const ol_layer_3 = {
  TileLayers: [
    {
      opacity: 0.5,
      source: {
        //url: "https://kartor.stockholm.se/bios/wms/app/baggis/web/WMS_STHLM_STOCKHOLMSKARTA_GRA_FORENKLAD_NT_RASTER",
        url: "http://kartor.stockholm.se/bios/wms/app/baggis/web/WMS_STHLM_STOCKHOLMSKARTA_GRA_FORENKLAD_NT",
        params: {
          VERSION: "1.1.1",
        },
      },
    },
  ],
};

// Demo2
export const ol_layer_4 = demo_layer;

// Demo3
export const ol_layer_5 = demo_layer;

// Kiruna mastertidplan
export const ol_layer_6 = {
  TileLayers: [
    {
      opacity: 0.5,
    },
  ],
};
