import React from "react";
import { mdiWindowClose } from "@mdi/js";
import Icon from "@mdi/react";

export default function CloseButton(props) {
  return (
    <button
      className="closeButton selected-close"
      onClick={props.closeFunction}
    >
      <Icon path={mdiWindowClose} title="Close Button" size={1.2} />
    </button>
  );
}
