import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function EditPhasePlan() {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const navigate = useNavigate();

  const location = useLocation();
  const { user, userList, phasePlan } = location.state;
  const { skedesplan_id } = location.state.user;
  const { supervising, start_date, stop_date, phase_name, color } = phasePlan;

  const [formData, setFormData] = useState({
    supervising: "",
    start_date: "",
    stop_date: "",
    phase_name: "",
    color: "",
  });

  return (
    <div className="window-container">
      <form className="form" onSubmit={() => {}}>
        <input
          className="form-control"
          name="supervising"
          onChange={() => {}}
          value={supervising}
        />
        <input
          type="date"
          className="form-control"
          name="start_date"
          onChange={() => {}}
          value={start_date}
        />
        <input
          type="date"
          placeholder={stop_date}
          className="form-control"
          name="stop_date"
          onChange={() => {}}
          value={stop_date}
        />
        <input
          className="form-control"
          name="phase_name"
          onChange={() => {}}
          value={phase_name}
        />
        <input
          className="form-control"
          name="color"
          onChange={() => {}}
          value={color}
        />
        <div className="legend-item-heading-container">
          <input
            id="admin"
            type="checkbox"
            name="isAdmin"
            className="box bg-black"
            onChange={() => {}}
            checked={formData.isAdmin}
          />
          <label htmlFor="admin" className="legend-item-header">
            Admin
          </label>
        </div>
        <button className="login-submit btn btn-primary">Ändra</button>
        <Link
          className="toggleLegendItems"
          to="/edit-phase-plans"
          state={{ user: user, skedesplan_id, userList }}
        >
          Tillbaka
        </Link>
      </form>
    </div>
  );
}
