import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const phasePlan = {
  id: "4",
  supervising: "Uppsala",
  start_date: "2024-09-15",
  stop_date: "2029-09-15",
  phase_name: "Ledningsomläggning",
  color: "224, 93, 93",
};

const phasePlansList = [
  phasePlan,
  phasePlan,
  phasePlan,
  phasePlan,
  phasePlan,
  phasePlan,
  phasePlan,
  phasePlan,
];

const headers = {
  id: "ID",
  supervising: "Ansvarig",
  start_date: "Startdatum",
  stop_date: "Slutdatum",
  phase_name: "Namn",
  color: "Färg",
};

export function EditPhasePlans() {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";

  const location = useLocation();
  const { user, userList } = location.state;
  const { skedesplan_id } = location.state.user;

  return (
    <div className="edit-phase-plans">
      <div className="grid">
        {Object.values(headers).map((header) => {
          return <div className="header">{header}</div>;
        })}
        {<h1 className="mobile-header">Skedesplaner</h1>}
        {phasePlansList.map((phasePlan) => {
          return (
            <Link
              to={"/edit-phase-plan"}
              state={{ user, skedesplan_id, userList, phasePlan }}
              className="row"
            >
              {Object.values(phasePlan).map((field) => (
                <div>{field}</div>
              ))}
            </Link>
          );
        })}
        <Link
          className="toggleLegendItems"
          to="/skedesplan"
          state={{ user, skedesplan_id, userList }}
        >
          Tillbaka
        </Link>
      </div>
    </div>
  );
}
