import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function ChangePassword(props) {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const navigate = useNavigate();

  // Read values passed from Dropdown component
  const location = useLocation();
  const { user, userList } = location.state;
  const { skedesplan_id, user_id, role, email } = location.state.user;
  const [formData, setFormData] = useState({
    password: "",
    passwordConfirm: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  async function changePassword(event) {
    event.preventDefault();
    if (formData.password === formData.passwordConfirm) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: user_id,
          password: formData.password,
          token: user.token,
        }),
      };

      const response = await fetch(`${apiURL}/change-password`, requestOptions);
      const data = await response.json();
      if (data.error) {
        alert(data.error);
        return navigate("/login");
      }
      alert("Password changed successfully");
      return navigate("/skedesplan", {
        state: { user, skedesplan_id, userList },
      });
    } else {
      alert("Passwords do not match");
      return;
    }
  }

  return (
    <div className="window-container">
      <form className="form" onSubmit={changePassword}>
        <input
          type="password"
          placeholder="New Password"
          className="form-control"
          name="password"
          onChange={handleChange}
          value={formData.password}
        />
        <input
          type="password"
          placeholder="Confirm new password"
          className="form-control"
          name="passwordConfirm"
          onChange={handleChange}
          value={formData.passwordConfirm}
        />
        <button className="login-submit btn btn-primary">Change</button>
        {user.length > 1 ? (
          <Link
            className="toggleLegendItems"
            to="/skedesplaner"
            state={{ user }}
          >
            Tillbaka
          </Link>
        ) : (
          <Link
            className="toggleLegendItems"
            to="/skedesplan"
            state={{ user: user, skedesplan_id, userList }}
          >
            Tillbaka
          </Link>
        )}
      </form>
    </div>
  );
}
