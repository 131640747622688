import React from "react";
import CloseButton from "./CloseButton";

export default function InfoBox(props) {
  let stagesData = [];

  function closeInfoBox() {
    props.setInfo(false);

    props.setData({
      ...props.data,
      features: props.data.features.map((f) => {
        return { ...f, properties: { ...f.properties, selected: 0 } };
      }),
    });
  }
  getAllStages();

  function getAllStages() {
    if (props.selected) {
      let stage = props.selected;
      let a = props.apiData;

      for (let i = 0; i < a.features.length; i++) {
        if (a.features[i].geom_id === stage.geom_id) {
          let stopdate = "- " + a.features[i].properties.stop_date;
          stagesData.push(
            <p key={a.features[i].properties.phase_name}>
              {a.features[i].properties.phase_name} <br />
              {a.features[i].properties.start_date}
              {a.features[i].properties.stop_date ? stopdate : ""}
            </p>
          );
        }
      }
    }
  }
  const stages = stagesData.map((s) => s);

  if (props.showInfo === false) return <div />;
  return (
    <div className="selected">
      <CloseButton closeFunction={closeInfoBox} />
      <h3>{props.selected?.infobox_header}</h3>
      <p className="infoHeader">{props.selected?.infobox_subheader}</p>
      {stages}
    </div>
  );
}
