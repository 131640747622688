import { Style, Fill, Stroke } from "ol/style";

export function layersStyle(features) {
  features.forEach((element) => {
    let style;
    if (element.values_.selected === 0) {
      style = new Style({
        stroke: new Stroke({
          color: "rgba(" + element.values_.color + ")",
          width: 1,
        }),
        fill: new Fill({
          color: "rgba(" + element.values_.color + ", 0.5)",
        }),
      });
    } else {
      style = new Style({
        stroke: new Stroke({
          color: "rgba(" + element.values_.color + ")",
          width: 1,
        }),
        fill: new Fill({
          color: "rgba(" + element.values_.color + ")",
        }),
      });
    }
    element.setStyle(style);
  });
}
