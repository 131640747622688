import React, { useState, useEffect, useRef } from "react";
import OlMap from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import empty from "../../data/Empty.json";
import { layersStyle } from "../layers";
import * as Properties from "./properties";

export default function PublicMap({ data, skedesplanId, ...props }) {
  const layers = Properties[`id${skedesplanId}Layers`];
  const view = Properties[`id${skedesplanId}View`];

  const [map, setMap] = useState();
  const mapElement = useRef();
  const mapRef = useRef();
  const [vectorLayer, setVectorLayer] = useState();
  const dataRef = useRef();

  mapRef.current = map;

  dataRef.current = data;

  // On first render data will be null so here we set data to an empty json file. On second render
  // the data will be populated.
  data
    ? (data = new GeoJSON().readFeatures(data))
    : (data = new GeoJSON().readFeatures(empty));

  useEffect(() => {
    const vectors = new VectorLayer({
      source: new VectorSource({
        features: data,
      }),
    });

    let initialMap;
    initialMap = new OlMap({
      target: mapElement.current,
      layers: layers(vectors),
      view,
      controls: [],
    });
    console.log(initialMap);
    setMap(initialMap);
    setVectorLayer(vectors);

    initialMap.on("click", featureClicked);
  }, []);

  useEffect(() => {
    if (vectorLayer !== undefined) {
      layersStyle(data);
      vectorLayer.setSource(
        new VectorSource({
          features: data,
        })
      );
    }
  }, [data]);

  //Calls onClick in App.js. Sends data with useRef.current, otherwise data will be null.
  //If there is any features where the user has clicked, take the first one(the feature that is on top) and send that feature.
  const featureClicked = (e) => {
    let features = [];

    mapRef.current.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
      features.push(feature);
    });
    const [firstFeature] = features.slice(0);
    if (firstFeature) props.onClick(firstFeature, dataRef.current);
  };

  return <div style={{ height: "100%" }} ref={mapElement} />;
}
