import TileWMS from "ol/source/TileWMS";
import OlView from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import Projection from "ol/proj/Projection";
import { ol_view_3 } from "../../../data/OlViewConfig";
import { ol_layer_3 } from "../../../data/OlTileLayerConfig";

const { TileLayers } = ol_layer_3;

const layers = TileLayers.map(({ source, ...rest }) => {
  return new TileLayer({ source: new TileWMS(source), ...rest });
});

function id3Layers(vectors) {
  return [...layers, vectors];
}

const { code, units } = ol_view_3["projection"];

const projection = new Projection({
  code,
  units,
});

const id3View = new OlView({ ...ol_view_3, projection });

export { id3Layers, id3View };

/*function id3Layers(vectors) {
  return [
    new TileLayer({
      source: new OSM(),
    }),
    vectors,
  ];
}

const { center, zoom } = ol_view_3;

const id3View = new OlView({
  center: transform(center, "EPSG:4326", "EPSG:3857"),
  zoom,
});

export { id3Layers, id3View };
*/
