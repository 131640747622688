import React, { useEffect } from "react";
import Header from "./Header";
import { useNavigate, useLocation } from "react-router-dom";

export default function ShowSkedesplaner(props) {
  const { state } = useLocation();

  const { user } = state ? state : { user: null }; // Read values passed from Login component
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("login");
    }
  }, []);

  let skedesplaner = [];

  function goToSkedesplan(userList, user, skedesplan_id) {
    sessionStorage.setItem("role", user.role);
    // sessionStorage.setItem("userId", user.id);
    navigate("/skedesplan", { state: { user, skedesplan_id, userList } });
  }

  user?.forEach((element) => {
    skedesplaner.push(
      <button
        key={`${element.user_id} ${element.client} ${element.skedesplan_id}`}
        onClick={(e) => {
          goToSkedesplan(user, element, element.skedesplan_id);
        }}
        className="skedesplan-btn btn-primary"
      >
        {element.client}
      </button>
    );
  });
  const skedesplanerJSX = skedesplaner.map((s) => s);
  return (
    <>
      <Header
        headerTitle={"Dina Skedesplaner"}
        user={user ? user[0] : {}}
        hideDropdown={true}
      />
      <div className="skedesplaner container">{skedesplanerJSX}</div>
    </>
  );
}
