import React, { useEffect, useRef, useState } from "react";
import LegendItem from "./LegendItem";
import LegendHeaderItem from "./LegendHeaderItem";

export default function Legend(props) {
  const itemsRef = useRef();
  const [expanded, setExpanded] = useState(true);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef);

  window.onresize = function () {
    // Checks if legend is hidden when window width is chsanged
    if (window.innerWidth > 991.98 && !expanded) {
      setExpanded(true);
    }
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function handleLegendChange(phase, bool) {
    props.setInfo(false);
    if (phase) {
      if (props.showLayer[phase] === true) {
        props.setLayer((prevState) => {
          return { ...prevState, [phase]: bool };
        });
      } else {
        props.setLayer((prevState) => {
          return { ...prevState, [phase]: bool };
        });
      }
    }
  }

  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Sets expanded to false if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          window.innerWidth <= 991.98
        ) {
          setExpanded(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function toggleLegend(e) {
    setExpanded(!expanded);
  }

  useEffect(() => {
    if (props.apiData !== undefined && props.apiData.features !== null) {
      const apiData = props.apiData;

      //Automatically update "senast reviderad" based on latest edited_at timestamp
      let editedAtArray = [];
      for (let k = 0; k < apiData.features.length; k++) {
        let editedAtDate = props.apiData.features[k].properties.edited_at;
        if (editedAtDate !== null) {
          editedAtDate = new Date(editedAtDate);
          editedAtArray.push(editedAtDate);
        }
      }
      if (editedAtArray.length !== 0) {
        let maxDate = Math.max.apply(null, editedAtArray);
        maxDate = new Date(maxDate);
        //maxDate.setDate(maxDate.getDate() + 1);
        maxDate = maxDate.toISOString().split("T")[0];
        let senasteRev = "Senast reviderad: " + maxDate;
        document.getElementById("sr").innerHTML = senasteRev;
      }

      let geometryNames = [];
      for (let j = 0; j < apiData.features.length; j++) {
        let object = apiData.features[j].properties;
        for (const property in object) {
          if (property === "supervising") {
            geometryNames.push(object[property]);
          }
        }
      }
      let uniqueGeometryNames = geometryNames.filter(onlyUnique);
      let items = [];

      for (let i = 0; i < uniqueGeometryNames.length; i++) {
        const element = uniqueGeometryNames[i];
        let uniqueItems = [];
        items.push(
          <LegendHeaderItem
            key={"header" + i}
            name={element}
            handleChange={handleLegendChange}
          />
        );
        for (let j = 0; j < apiData.features.length; j++) {
          let object = apiData.features[j].properties;
          for (const property in object) {
            if (
              object[property] === element &&
              !uniqueItems.some((e) => e === object.phase_name)
            ) {
              items.push(
                <LegendItem
                  key={object.geom_id + object.start_date + object.stop_date}
                  phase={object.supervising + object.phase_name}
                  name={object.phase_name}
                  color={object.color}
                  className={object.supervising}
                  handleChange={handleLegendChange}
                />
              );
              uniqueItems.push(object.phase_name);
            }
          }
        }
      }
      itemsRef.current = items.map((i) => i);
    }
  }, [props.apiData]);

  if (!props.showLegend) return <div />;
  return (
    <div
      className={
        expanded ? "expanded legend col-lg-2" : "album legend col-lg-2"
      }
      ref={wrapperRef}
    >
      <button
        className="btn btn-secondary expand-legend"
        onClick={toggleLegend}
      >
        {expanded ? (
          <i className="fa-light fa-chevrons-left"></i>
        ) : (
          <i className="fa-light fa-chevrons-right"></i>
        )}
      </button>
      <div className="legend-wrapper">
        <h3>Teckenförklaring</h3>
        {itemsRef.current}
        <footer className="legend-footer">
          <p className="center badge badge-info">Status: Planeringsmaterial</p>
          <p className="center badge badge-outline" id="sr">
            Senast reviderad: 2022-12-08
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ondemand.sweco.se/produkt/skedesplaner/#single-product-extra-blocks"
            className="contactButton btn btn-primary"
          >
            Kontakta oss!
          </a>
        </footer>
      </div>
    </div>
  );
}
