import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function CreateAccount(props) {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const navigate = useNavigate();

  const location = useLocation();
  const { user, userList } = location.state;
  const { skedesplan_id, user_id, role, email } = location.state.user;

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    passwordConfirm: "",
    isAdmin: false,
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  async function createUser(event) {
    event.preventDefault();

    let admin;
    if (formData.isAdmin) {
      admin = "admin";
    } else {
      admin = null;
    }

    if (formData.email !== "" && formData.password !== "") {
      if (formData.password === formData.passwordConfirm) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: user.token,
            email: formData.email,
            password: formData.password,
            isAdmin: admin,
            skedesplanId: skedesplan_id,
          }),
        };

        const response = await fetch(`${apiURL}/create-user`, requestOptions);
        const data = await response.json();
        if (data.error) {
          alert(data.error);
          if (data.error === "Can not create user, user already exists") return;
          return navigate("/login");
        }

        alert(`${data.message}`);
        return navigate("/skedesplan", {
          state: { user, skedesplan_id, userList },
        });
      } else {
        alert("Passwords do not match");
        return;
      }
    } else {
      alert("All fields required");
    }
  }

  return (
    <div className="window-container">
      <form className="form" onSubmit={createUser}>
        <input
          type="email"
          placeholder="Email address"
          className="form-control"
          name="email"
          onChange={handleChange}
          value={formData.email}
        />
        <input
          type="password"
          placeholder="Password"
          className="form-control"
          name="password"
          onChange={handleChange}
          value={formData.password}
        />
        <input
          type="password"
          placeholder="Confirm password"
          className="form-control"
          name="passwordConfirm"
          onChange={handleChange}
          value={formData.passwordConfirm}
        />
        <div className="legend-item-heading-container">
          <input
            id="admin"
            type="checkbox"
            name="isAdmin"
            className="box bg-black"
            onChange={handleChange}
            checked={formData.isAdmin}
          />
          <label htmlFor="admin" className="legend-item-header">
            Admin
          </label>
        </div>
        <button className="login-submit btn btn-primary">Create</button>
        <Link
          className="toggleLegendItems"
          to="/skedesplan"
          state={{ user: user, skedesplan_id, userList }}
        >
          Tillbaka
        </Link>
      </form>
    </div>
  );
}
