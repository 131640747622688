import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

export default function Users() {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const { user, userList } = location.state;
  const { skedesplan_id } = location.state.user;

  const navigate = useNavigate();

  useEffect(() => {
    async function getUsers() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: user.user_id,
          skedesplanId: skedesplan_id,
          token: user.token,
        }),
      };
      const response = await fetch(`${apiURL}/users`, requestOptions);
      const data = await response.json();
      if (data.error) {
        alert(data.error);
        return navigate("/login");
      }
      setUsers(data);
    }

    getUsers();
  }, []);

  async function deleteUser(user_id, user_skedesplan) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_skedeplanid: user_skedesplan,
        token: user.token,
      }),
    };

    await fetch(`${apiURL}/delete-user`, requestOptions);

    setUsers((prevUsers) => prevUsers.filter((u) => u.user_id !== user_id));
  }

  return (
    <div className="window-container">
      <div className="delete-container">
        {users.map((u) => (
          <div className="delete-user" key={u.user_id + u.skedesplan_id}>
            <label>{u.email}</label>
            <button
              className="btn btn-warning"
              onClick={() => deleteUser(u.user_id, u.user_skedeplanid)}
            >
              Delete
            </button>
          </div>
        ))}
        <Link
          className="toggleLegendItems"
          to="/skedesplan"
          state={{ user: user, skedesplan_id, userList }}
        >
          Tillbaka
        </Link>
      </div>
    </div>
  );
}
