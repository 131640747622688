import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Dropdown(props) {
  let userEmail;
  if (props.user) {
    userEmail = props.user.email;
  } else {
    userEmail = "";
  }

  return (
    <ul className="nav-toolbar">
      <NavItem userEmail={userEmail}>
        {/* eslint-disable-next-line prettier/prettier */}
        {userEmail && !props.hideDropdown ? (
          <DropdownMenu
            user={props.user}
            userList={props.userList}
            hideDropdown={props.hideDropdown}

            // role={props.role}
            // logout={props.logout}
          ></DropdownMenu>
        ) : null}
      </NavItem>
    </ul>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);
  const node = useRef();

  function clicked(e) {
    setOpen(!open);
  }

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      //Do nothing on click on dropdown
      return;
    }
    // hide dropdown
    setOpen(false);
  };

  // Do something after component renders
  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [open]);

  return (
    <li ref={node} className="nav-item">
      <button
        // title={props.userEmail} Do we want a title?
        className="btn btn-user"
        onClick={clicked}
      >
        {props.icon}
      </button>

      {open && props.children}
    </li>
  );
}

function DropdownMenu(props) {
  function DropdownItem({
    path,
    state,
    loggOut,
    icon = "fa-light fa-map-pin",
    children,
  }) {
    return (
      <Link className="dropdown-item" to={path} state={state} onClick={loggOut}>
        {/* <span className="icon-button">{props.leftIcon}</span> */}
        <i className={icon}></i>
        {children}
      </Link>
    );
  }

  // Change to Localstorage? With session you can go back in browser after logout
  function loggOut() {
    sessionStorage.clear();
  }
  //creating a object to send both objects to other components
  const userObj = {
    user: props.user,
    userList: props.userList,
  };

  let role = sessionStorage.getItem("role");

  return (
    <div className="dropdown-menu dropdown-menu-right dropdown-menu-wrap show">
      {/* We can use icons aswell, here is an example, css is already done, 
      uncomment the span in DropdownItem to try =>
      <DropdownItem leftIcon="🤞" path={"/change-password"} state={userObj}>
        Ändra lösenord
      </DropdownItem> */}
      {props.userList ? (
        <DropdownItem
          path={"/skedesplaner"}
          state={{ user: props.userList }}
          icon="fa-light fa-map"
        >
          Mina skedesplaner
        </DropdownItem>
      ) : null}
      <DropdownItem
        path={"/change-password"}
        state={userObj}
        icon="fa-light fa-key"
      >
        Ändra lösenord
      </DropdownItem>
      {role === "admin" ? (
        <DropdownItem
          path={"/create-account"}
          state={userObj}
          icon="fa-light fa-user"
        >
          Lägg till användare
        </DropdownItem>
      ) : null}
      {role === "admin" ? (
        <DropdownItem path={"/users"} state={userObj} icon="fa-light fa-user">
          Ta bort användare
        </DropdownItem>
      ) : null}
      {role === "admin" ? (
        <DropdownItem
          path={"/edit-phase-plans"}
          state={userObj}
          icon="fa-light fa-pen"
        >
          Ändra skedesplan
        </DropdownItem>
      ) : null}
      <DropdownItem
        path={"/login"}
        loggOut={loggOut}
        icon="fa-light fa-arrow-right-from-bracket"
      >
        Log out
      </DropdownItem>
    </div>
  );
}
