// To find the your skedesplan -- Use Ctrl+F (VSCode) and search for your skedesplan.
// All new maps should have a comment above with the name of the Skedesplan (ID is what the id is in the PROD database)

// Sundbyberg/SNS ID 1
export const ol_view_1 = {
  target: null,
  center: [148017.91559999995, 6583055.4253],
  zoom: 5.5,
  projection: { code: "EPSG:3011", units: "m" },
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};

// Demo ID 2
export const ol_view_2 = {
  target: null,
  center: [154254.793303, 6578767.878556],
  zoom: 5.5,
  projection: { code: "EPSG:3011", units: "m" },
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};

// Årstafältet ID 3
export const ol_view_3 = {
  target: null,
  center: [152527.838361, 6575185.890049],
  zoom: 5.5,
  projection: { code: "EPSG:3011", units: "m" },
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};

// Demo2 ID 4
export const ol_view_4 = {
  target: null,
  center: [151870.7, 6579723.9],
  zoom: 5.5,
  projection: { code: "EPSG:3011", units: "m" },
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};

// Demo3 ID 5
export const ol_view_5 = {
  target: null,
  center: [154262.7, 6577453.1],
  zoom: 5.5,
  projection: { code: "EPSG:3011", units: "m" },
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};

// Kiruna mastertidplan ID 6
export const ol_view_6 = {
  target: null,
  center: [723022.8400229591, 7535571.7337944565],
  zoom: 5.5,
  resolutions: [
    117.1875, 58.59375, 29.296875, 14.6484375, 7.32421875, 3.662109375,
    1.8310546875, 0.91552734375, 0.457763671875, 0.2288818359375,
    0.11444091796875,
  ],
};
