export function updateStages(featureCollection) {
  const { features } = featureCollection;
  return {
    type: "FeatureCollection",
    features: features.map((f) => {
      return {
        ...f,
        properties: {
          ...f.properties,
          selected: f.properties.selected || 0,
        },
      };
    }),
  };
}
