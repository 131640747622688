import React from "react";

export default function LegendItem(props) {
  return (
    <div className="legend-item ">
      <input
        style={{ backgroundColor: `rgba(${props.color})` }}
        type="checkbox"
        id={props.phase}
        name={props.name}
        defaultChecked={true}
        className={`box ${props.className}`}
        onChange={(e) => {
          props.handleChange(e.target.id, e.target.checked);
        }}
      />
      <label className={props.className} htmlFor={props.phase}>
        {props.name}
      </label>
    </div>
  );
}
