import React from "react";
import { Routes, Route } from "react-router-dom";

import Skedesplan from "./components/Skedesplan";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Login from "./components/Login";
import ShowSkedesplan from "./components/ShowSkedesplaner";
import CreateAccount from "./components/CreateAccount";
import Users from "./components/Users";
import ChangePassword from "./components/ChangePassword";
import { EditPhasePlans } from "./components/EditPhasePlans";
import { EditPhasePlan } from "./components/EditPhasePlan";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        {/* Error page ska läggas till */}
        <Route path="/skedesplan" element={<Skedesplan />} />
        <Route path="skedesplaner" element={<ShowSkedesplan />} />
        <Route path="create-account" element={<CreateAccount />} />
        <Route path="users" element={<Users />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="edit-phase-plans" element={<EditPhasePlans />} />
        <Route path="edit-phase-plan" element={<EditPhasePlan />} />
        <Route path="*" element={<Login />} />
      </Route>
      <Route path="login" element={<Login />} />
      {/* lägga till dropdown här */}
    </Routes>
  );
}
