import OSM from "ol/source/OSM";
import OlView from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import { ol_layer_6 } from "../../../data/OlTileLayerConfig";
import { ol_view_6 } from "../../../data/OlViewConfig";
import { get as getProjection } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";

// Define and register EPSG:3006 projection
proj4.defs(
  "EPSG:3006",
  "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

const { TileLayers } = ol_layer_6;

const layers = TileLayers.map(({ opacity }) => {
  return new TileLayer({
    source: new OSM({
      crossOrigin: "anonymous",
    }),
    opacity: opacity,
  });
});

function id6Layers(vectors) {
  return [...layers, vectors];
}

const projection = getProjection("EPSG:3006");

const id6View = new OlView({
  ...ol_view_6,
  projection: projection,
});

export { id6Layers, id6View };
