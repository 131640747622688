/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import Legend from "./Legend";
import InfoBox from "./InfoBox";
import Header from "./Header";
import PublicMap from "../config/Maps/MyMap";
import { useLocation, useNavigate } from "react-router-dom";
import { updateStages } from "../utils";

export default function App(props) {
  const apiURL = process.env.REACT_APP_API_URL || "http://localhost:5000";

  const [selected, setSelected] = useState(null);
  const [showInfo, setInfo] = useState(false);
  const [date, setDate] = useState("2022-01-01");
  const [data, setData] = useState(null);
  const [showLegend, setLegend] = useState(true);
  const [apiData, setApiData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [apiCount, setApiCount] = useState();
  const [showLayer, setLayer] = useState({});

  const navigate = useNavigate();
  const { state } = useLocation();
  //user variables
  const { userList, user, skedesplan_id } = state
    ? state
    : { userList: null, user: null, skedesplan_id: null }; // Read values passed from Login component

  // const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  // const [role, setRole] = useState(sessionStorage.getItem("role"));

  //When showLayer updates from Legend Load new Data
  useEffect(() => {
    loadData(date);
    getApiCount(); // Ska vi ha denna i LoadData? blir väldigt många fetches meeen
  }, [showLayer]);

  useEffect(() => {
    if (!isLoading && apiData.features !== null) createShowLayer();
  }, [apiData]);

  useEffect(() => {
    fetchApi();

    if (!state) {
      navigate("login");
    }
  }, []);

  function createShowLayer() {
    let layerObj = {};
    let check = [];
    for (let i = 0; i < apiData.features.length; i++) {
      if (!check.some((e) => e === apiData.features[i].properties.phase_name)) {
        // Uses both properties to create unique layer names
        let phase =
          apiData.features[i].properties.supervising +
          apiData.features[i].properties.phase_name;
        addProp(layerObj, phase, true);
      }
    }
    setLayer(layerObj);
  }
  function addProp(obj, propName, propValue) {
    obj[propName] = propValue;
  }
  async function fetchApi() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        skedesplan_id: skedesplan_id,
        token: user.token,
      }),
    };

    const response = await fetch(`${apiURL}/features`, requestOptions);
    const data = await response.json();
    if (data.error) {
      alert(data.error);
      return navigate("/login");
    }
    setApiData(data[0].jsonb_build_object);
    setIsLoading(false);
  }

  //Check if there is new data to retrive from DB
  async function getApiCount() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        skedesplan_id: skedesplan_id,
        token: user.token,
      }),
    };
    const response = await fetch(`${apiURL}/features/count`, requestOptions);
    const count = await response.json();
    if (count.error) {
      alert("Session ended, please login again");
      return navigate("/login");
    }
    if (count !== apiCount) {
      setApiCount(count);
      fetchApi();
    }
  }

  useEffect(() => {
    if (!data) loadData(date);
  });

  function loadData(dateParam) {
    if (!isLoading && showLayer && apiData.features !== null) {
      const d = apiData;
      var filteredData = {};

      filteredData.type = "FeatureCollection";

      filteredData.name = "9980_skedesplanering_220414";

      filteredData.features = [];
      for (let i = 0; i < d.features.length; i++) {
        let phase =
          d.features[i].properties.supervising +
          d.features[i].properties.phase_name;

        if (
          d.features[i].properties.start_date <= dateParam &&
          d.features[i].properties.stop_date >= dateParam &&
          showLayer[phase] === true
        ) {
          filteredData.features.push(d.features[i]);
        } else if (
          d.features[i].properties.start_date <= dateParam &&
          d.features[i].properties.stop_date === null &&
          showLayer[phase] === true
        ) {
          filteredData.features.push(d.features[i]);
        }
      }
      setData(updateStages(filteredData));
    }
  }

  // On Slider change.
  function updateChoice(dateParam) {
    setDate(dateParam);
    loadData(dateParam);
  }

  // function toggleLegend() {
  //   if (showLegend === true) {
  //     setLegend(false);
  //   } else {
  //     setLegend(true);
  //   }
  // }

  function onClick(feature, Data) {
    const b = feature.values_;
    setSelected(b);

    setData({
      ...Data,
      features: Data.features.map((f) => {
        if (f.geom_id === b?.geom_id) {
          return { ...f, properties: { ...f.properties, selected: 1 } };
        } else {
          return { ...f, properties: { ...f.properties, selected: 0 } };
        }
      }),
    });

    if (b?.geom_id != null) {
      setInfo(true);
    } else {
      setInfo(false);
    }
  }

  function showSite() {
    return (
      <div className="container white develop-content">
        <Header headerTitle={user?.client} user={user} userList={userList} />
        <div className="mapArea">
          <div id="map" className="mapDiv">
            <PublicMap
              data={data}
              skedesplanId={skedesplan_id}
              onClick={onClick}
            />
            <InfoBox
              selected={selected}
              showInfo={showInfo}
              setInfo={setInfo}
              data={data}
              setData={setData}
              apiData={apiData}
            />
          </div>
          <Legend
            showLegend={showLegend}
            showLayer={showLayer}
            setLayer={setLayer}
            date={date}
            data={data}
            apiData={apiData}
            loadData={loadData}
            setInfo={setInfo}
            // updateLayers={updateLayers}
          />
        </div>
        <Slider
          containerComponent={props.containerComponent}
          date={date}
          data={apiData}
          onChange={updateChoice}
          showLayer={showLayer}
        />
      </div>
    );
  }

  return showSite();
}
